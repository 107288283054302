#tMap {
  @media screen and (min-width: 1024px) {
    .task-info {
      width: 400px;
      word-wrap: break-word;
      word-break: break-all;
      white-space: normal;
    }
  }

  @media screen and (max-width: 1023px) {
    .task-info {
      width: 60vw;
      word-wrap: break-word;
      word-break: break-all;
      white-space: normal;
    }
  }

  .info-title {
    font-size: 18px;
    width: 100%;

  }

  .info-address {
    width: 100%;
    color: rgb(88, 88, 88);
    line-height: 1.8;
    margin: 20px 0 10px;
  }

  .info-service {
    color: rgb(88, 88, 88);
    line-height: 1.8;
  }

  .info-tel {
    margin-top: 10px;
    text-decoration: underline;
  }
}