.mobile-header {
  width: 100%;
  height: 73px;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1002;
  display: flex;
  padding: 0 20px;
  box-sizing: border-box;
  background-color: #dcddde;
  justify-content: flex-end;
  transition: height .4s;

  .h-top {
    width: 100%;
    text-align: center;
    position: absolute;
    height: 40px;
    color: #717171;
    top: 0;
    left: 0;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    letter-spacing: 3px;
    transition: top .4s;
  }

  .logo {
    width: 169px;
    height: 73px;

    .logo-link {
      display: block;
      width: 100%;
      height: 100%;
      line-height: 26px;

      .logo-img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        display: block;
      }
    }
  }

  .btns {
    font-size: 18px;
    display: flex;
    align-items: center;

    img {
      margin-right: 10px;
      width: 18px;
      height: 18px;

      &:last-child {
        margin-right: 0;
      }
    }

    .user-email {
      width: 22px;
      height: 16px;
    }
  }
}

.header-drawer {
  font-family: "Centra Book", Arial;

  .drawer-top {
    padding: 0 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 46px;
    transition: height .25s;
    overflow: hidden;

    .head-left {
      width: 200px;
      font-weight: 600;

      .drawer-logo {
        width: 200px;
      }
    }

    .head-right {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      img {
        width: 18px;
        height: 18px;
      }

      .drawer-search {
        margin-right: 10px;
      }
    }
  }

  // 搜索框
  .search-form {
    margin: 10px 20px;
    border: 1px solid #000;
    display: flex;
    align-items: center;
    height: 40px;
    padding: 0 15px;

    .search-icon {
      width: 18px;
      height: 18px;
    }

    .search-input {
      flex: 1;
      border: none;
      outline: medium;
      padding: 0 10px;
      background-color: transparent;
    }

    .search-close-icon {
      width: 12px;
      height: 12px;
    }
  }

  .search-result {
    border-top: 1px solid #000;
    padding-bottom: 65px;
    margin: 18px 20px 0;

    .res-title {
      font-size: 16px;
      font-weight: bold;
      line-height: 44px;
    }

    ul.res-link {
      padding: 0 15px;
      font-size: 16px;
      line-height: 34px;
    }

  }

  // 一级
  .mob-nav {
    margin: 0 20px;
    line-height: 48px;
    color: #000;
    font-weight: 600;

    li {
      display: flex;
      font-size: 16px;
    }
  }

  // 二级
  .sub-nav {
    display: flex;
    flex-direction: column;

    .sub-nav-header {
      margin: 0 20px;
      height: 104px;

      .sub-title {
        font-size: 18px;
        font-weight: bold;
        color: #000;
      }

      .sub-height-close {
        width: 14px;
        height: 14px;
      }
    }

    dl {
      line-height: 50px;
      font-size: 16px;

      dt {
        font-weight: bold;
        color: #000;
        margin: 0 20px;
        border-top: 1px solid #ccc;
      }

      dd {
        background-color: #fff;
        padding: 0 20px;

        a {
          color: #a1a1a1;
        }

        &:last-child {
          background-color: transparent;

          a {
            color: #000;
            font-weight: bold;
          }
        }
      }
    }

    .sub-img {
      width: 100%;
      margin-bottom: 1px;
      display: block;
    }
  }

  .drawer-bottom {
    margin: 0 20px;
    display: flex;
    flex-direction: column;
    font-size: 12px;
    line-height: 40px;
    padding-bottom: 65px;

    .footer-nav {
      color: #a1a1a1;
    }
  }

  .footer-te {
    position: absolute;
    bottom: 0;
    left: 5%;
    width: 90%;
    height: 62px;
    color: #272727;
    display: flex;
    align-items: center;
    border-top: 1px solid #000;
    background-color: #dcddde;

    .global-icon {
      margin-right: 30px;
      font-size: 18px;
    }
  }
}