
// 重置传来的html样式
.react-html{
  // p, dl{
  //   margin-block-start: 1em;
  //   margin-block-end: 1em;
  //   display: block;
  // }
  // dt{
  //   display: block;
  // }
  // dd{
  //   display: block;
  //   margin-inline-start: 40px;
  // }
  // ul, ol{
  //   display: block;
  //   list-style-type: disc;
  //   margin-block-start: 1em;
  //   margin-block-end: 1em;
  //   padding-inline-start: 40px;
  //   li{
  //     display: list-item;
  //   }
  // }
  // h1{
  //   display: block;
  //   font-size: 2em;
  //   margin-block-start: 0.67em;
  //   margin-block-end: 0.67em;
  //   margin-inline-start: 0px;
  //   margin-inline-end: 0px;
  //   font-weight: bold;
  // }
  // h2{
  //   display: block;
  //   font-size: 1.5em;
  //   margin-block-start: 0.83em;
  //   margin-block-end: 0.83em;
  //   margin-inline-start: 0px;
  //   margin-inline-end: 0px;
  //   font-weight: bold;
  // }
  // h3{
  //   display: block;
  //   font-size: 1.17em;
  //   margin-block-start: 1em;
  //   margin-block-end: 1em;
  //   margin-inline-start: 0px;
  //   margin-inline-end: 0px;
  //   font-weight: bold;
  // }
  // h4{
  //   display: block;
  //   margin-block-start: 1.33em;
  //   margin-block-end: 1.33em;
  //   margin-inline-start: 0px;
  //   margin-inline-end: 0px;
  //   font-weight: bold;
  // }
  // h5{
  //   display: block;
  //   font-size: 0.83em;
  //   margin-block-start: 1.67em;
  //   margin-block-end: 1.67em;
  //   margin-inline-start: 0px;
  //   margin-inline-end: 0px;
  //   font-weight: bold;
  // }
  // h6{
  //   display: block;
  //   font-size: 0.67em;
  //   margin-block-start: 2.33em;
  //   margin-block-end: 2.33em;
  //   margin-inline-start: 0px;
  //   margin-inline-end: 0px;
  //   font-weight: bold;
  // }
  // b{
  //   font-weight: bold;
  // }
  // i{
  //   font-style: italic;
  // }
  // img{
  //   display: inline-block;
  // }
  // a{
  //   // text-decoration: underline;
  //   cursor: pointer;
  // }
}

.react-html{
  .title-h2{
    display: block;
    font-size: 23px;
    line-height: 2;
    color: #fff;
  }
  .title-h3{
    display: block;
    font-size: 20px;
    line-height: 2;
    color: #fff;
  }
  .title-h4{
    display: block;
    font-size: 17px;
    line-height: 1.6;
    color: #fff;
  }
  .title-h5{
    display: block;
    font-size: 14px;
    line-height: 1.4;
    color: #fff;
  }
  .title-h6{
    display: block;
    font-size: 12px;
    line-height: 1.4;
    color: #fff;
  }
  .text-content{
    display: block;
    font-size: 17px;
    line-height: 2;
    color: #000;
  }
  .color-white{
    color: #fff!important;
  }
  .color-black{
    color: #000!important;
  }
  // 白底黑字
  .btn-bg-white{
    background-color: #fff!important;
    border: 2px solid #fff!important;
    margin-right: 35px;
    color: #000!important;
    text-decoration: none;
    font-weight: bold;
    &:hover{
      background-color: #000!important;
      color: #fff!important;
      border-color: #000!important;
    }
  }
  // 黑底白字
  .btn-bg-black{
    background-color: #000!important;
    border: 2px solid #000!important;
    margin-right: 35px;
    color: #fff!important;
    text-decoration: none;
    font-weight: bold;
    &:hover{
      background-color: #444!important;
      color: #fff!important;
      border-color: #444!important;
    }
  }
  // 透明底黑字
  .btn-bg-tran{
    background-color: transparent;
    border: 2px solid #000!important;
    margin-right: 35px;
    color: #000!important;
    text-decoration: none;
    font-weight: bold;
    &:hover{
      background-color: #000!important;
      color: #fff!important;
      border-color: #000!important;
    }
  }

  .btn-link-white{
    display: inline-block;
    text-decoration: underline;
    color: rgba(250, 250, 250, 1);
    transition: color .2s cubic-bezier(.4, 0, 1, 1);
    cursor: pointer;
    font-size: 18px;
    padding: 0 10px;
    &:hover{
      color: rgba(250, 250, 250, .8);
    }
  }
  .btn-link-black{
    text-decoration: underline;
    color: rgba(0, 0, 0, 1);
    transition: color .2s cubic-bezier(.4, 0, 1, 1);
    cursor: pointer;
    font-size: 18px;
    padding: 0 10px;
    &:hover{
      color: rgba(0, 0, 0, .8);
    }
  }

  .letter-spacing2{
    letter-spacing: 2px;
    text-indent: 2px;
    display: inline-block;
  }
  .letter-spacing4{
    letter-spacing: 4px;
    text-indent: 4px;
    display: inline-block;
  }
  .letter-spacing6{
    letter-spacing: 6px;
    text-indent: 6px;
    display: inline-block;
  }
  .letter-spacing8{
    letter-spacing:8px;
    text-indent: 8px;
    display: inline-block;
  }
  .letter-spacing10{
    letter-spacing: 10px;
    text-indent: 10px;
    display: inline-block;
  }
  .letter-spacing12{
    letter-spacing: 12px;
    text-indent: 12px;
    display: inline-block;
  }
  .letter-spacing14{
    letter-spacing: 14px;
    text-indent: 14px;
    display: inline-block;
  }
  .letter-spacing16{
    letter-spacing: 16px;
    text-indent: 16px;
    display: inline-block;
  }
  .letter-spacing18{
    letter-spacing: 18px;
    text-indent: 18px;
  }
  .letter-spacing20{
    letter-spacing: 20px;
    text-indent: 20px;
    display: inline-block;
  }
  .letter-spacing22{
    letter-spacing: 22px;
    text-indent: 22px;
  }
  .letter-spacing24{
    letter-spacing: 24px;
    text-indent: 24px;
    display: inline-block;
  }
  .letter-spacing26{
    letter-spacing: 26px;
    text-indent: 26px;
    display: inline-block;
  }
  .letter-spacing28{
    letter-spacing: 28px;
    text-indent: 28px;
    display: inline-block;
  }
  .letter-spacing30{
    letter-spacing: 30px;
    text-indent: 30px;
    display: inline-block;
  }
  .letter-spacing40{
    letter-spacing: 40px;
    text-indent:40px ;
    display: inline-block;
  }
  .text-center{
    text-align: center!important;
  }
  .text-left{
    text-align: left!important;
  }
  .text-right{
    text-align: right!important;
  }
  .font-weight-bold{
    font-weight: bold!important;
  }
  .font-weight-normal{
    font-weight: normal!important;
  }
  .div-center{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .div-left{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .div-right{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
}
  // pc特有的样式
@media screen and (min-width: 1081px) {
  .react-html{
    .mob_show{
      display: none!important;
    }
    .postion-center{
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
    .postion-left{
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      padding-left: 5%;
      align-items: flex-start;
      justify-content: center;
      text-align: center;
    }
    .postion-right{
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      padding-right: 5%;
      align-items: flex-end;
      justify-content: center;
      text-align: center;
    }
    .postion-top{
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      padding-top: 5%;
      align-items: center;
      justify-content: flex-start;
      text-align: center;
    }
    .postion-btm{
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      padding-bottom: 2%;
      align-items: center;
      justify-content: flex-end;
      text-align: center;
    }
    .title-h0{
      display: block;
      font-size: 36px;
      line-height: 2;
      color: #fff;
    }
    .title-h1{
      display: block;
      font-size: 30px;
      line-height: 2;
      color: #fff;
    }
    
    // 按钮大小
    .btn-default{
      display: inline-block;
      padding: 0 40px;
      line-height: 57px;
      height: 57px;
      font-size: 16px;
      background-color: transparent;
      border: 2px solid #fff;
      transition: color .2s cubic-bezier(.4, 0, 1, 1), background-color .2s cubic-bezier(.4, 0, 1, 1);
      margin-right: 27px;
      margin-left: 27px;
      color: #fff;
      text-decoration: none;
      font-weight: bold;
      min-width: 240px;
      &:hover{
        background-color: #fff;
        color: #000;
        border-color: #fff;
      }
    }
    .btn-mini{
      display: inline-block;
      padding: 0 20px;
      line-height: 42px;
      height: 42px;
      font-size: 14px;
      background-color: transparent;
      border: 2px solid #fff;
      transition: color .2s cubic-bezier(.4, 0, 1, 1), background-color .2s cubic-bezier(.4, 0, 1, 1);
      margin-right: 22px;
      margin-left: 22px;
      color: #fff;
      text-decoration: none;
      font-weight: bold;
      min-width: 220px;
      &:hover{
        background-color: #fff;
        color: #000;
        border-color: #fff;
      }
    }
    .btn-large{
      display: inline-block;
      padding: 0 45px;
      line-height: 64px;
      height: 64px;
      font-size: 18px;
      background-color: transparent;
      border: 2px solid #fff;
      transition: color .2s cubic-bezier(.4, 0, 1, 1), background-color .2s cubic-bezier(.4, 0, 1, 1);
      margin-right: 35px;
      margin-left: 35px;
      color: #fff;
      text-decoration: none;
      font-weight: bold;
      min-width: 260px;
      &:hover{
        background-color: #fff;
        color: #000;
        border-color: #fff;
      }
    }
  }
}
  // 移动特有的样式
@media screen and (max-width: 1080px) {
  .pc_show{
    display: none!important;
  }
  .empty-block.react-html{
    width: 100%!important;
    left: 0!important;
  }
  .react-html{
    .postion-center{
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
    .postion-left{
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      padding-left: 5%;
      align-items: flex-start;
      justify-content: center;
      text-align: center;
    }
    .postion-right{
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      padding-right: 5%;
      align-items: flex-end;
      justify-content: center;
      text-align: center;
    }
    .postion-top{
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      padding-top: 5%;
      align-items: center;
      justify-content: flex-start;
      text-align: center;
    }
    .postion-btm{
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      padding-bottom: 5%;
      align-items: center;
      justify-content: flex-end;
      text-align: center;
    }
    width: 90%!important;
    left: 5%!important;
    margin: 0 auto;
    .btn-default,.btn-mini, .btn-large{
      margin-bottom: 10px;
    }
    
    .title-h0{
      display: block;
      font-size: 28px;
      line-height: 2;
      color: #fff;
    }
    .title-h1{
      display: block;
      font-size: 26px;
      line-height: 2;
      color: #fff;
    }
    
    // 按钮大小
    .btn-default{
      display: inline-block;
      padding: 0 26px;
      line-height: 48px;
      height: 48px;
      font-size: 16px;
      background-color: transparent;
      border: 2px solid #fff;
      transition: color .2s cubic-bezier(.4, 0, 1, 1), background-color .2s cubic-bezier(.4, 0, 1, 1);
      margin: 0 14px;
      color: #fff;
      text-decoration: none;
      font-weight: bold;
      min-width: 160px;
      &:hover{
        background-color: #fff;
        color: #000;
        border-color: #fff;
      }
    }
    .btn-mini{
      display: inline-block;
      padding: 0 20px;
      line-height: 36px;
      height: 36px;
      font-size: 14px;
      background-color: transparent;
      border: 2px solid #fff;
      transition: color .2s cubic-bezier(.4, 0, 1, 1), background-color .2s cubic-bezier(.4, 0, 1, 1);
      margin: 0 14px;
      color: #fff;
      text-decoration: none;
      font-weight: bold;
      min-width: 140px;
      &:hover{
        background-color: #fff;
        color: #000;
        border-color: #fff;
      }
    }
    .btn-large{
      display: inline-block;
      padding: 0 45px;
      line-height: 56px;
      height: 56px;
      font-size: 18px;
      background-color: transparent;
      border: 2px solid #fff;
      transition: color .2s cubic-bezier(.4, 0, 1, 1), background-color .2s cubic-bezier(.4, 0, 1, 1);
      margin: 0 14px;
      color: #fff;
      text-decoration: none;
      font-weight: bold;
      min-width: 180px;
      &:hover{
        background-color: #fff;
        color: #000;
        border-color: #fff;
      }
    }
  }
}