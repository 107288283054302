// 在线字体包
// @import url('https://fonts.font.im/css?family=Hanalei+Fill');
// @import url('https://fonts.font.im/css?family=Roboto');

@font-face {
  font-family: 'Centra Icon';
  font-style: normal;
  font-weight: 500;
  src: url('../font/Blank-Theme-Icons.woff2') format('woff'), 
}

@font-face {
  font-family: 'Centra Book';
  font-style: normal;
  font-weight: 400;
  src: url('../font/CentraNo1-Book.woff') format('woff'), 
}

@font-face {
  font-family: 'Centra No1';
  font-style: normal;
  font-weight: 500;
  src: url('../font/CentraNo1-Medium.woff') format('woff'), 
}