.pc-footer {
  width: 100%;
  min-height: 400px;
  padding: 68px 20px 20px 50px;
  box-sizing: border-box;
  background-color: #EDEDED;
  position: relative;
  font-family: "Centra Book",Arial;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .link-list {
    display: flex;
    .list-item {
      width: 220px;
      &:last-of-type {
        margin-right: 0;
      }
      .list-item-title {
        font-size: 20px;
        color: #272727;
        line-height: 48px;
        margin-bottom: 10px;
      }
      .link-item .link-to {
        color: #9B9B9B;
        font-size: 15px;
        line-height: 36px;
        &:hover {
          text-decoration: underline;
          color: #272727;
        }
      }
    }
    .Newsletter{
      width: 623px;
      .form-let{
        width: 100%;
        .input-let{
          margin-right: 10px;
        }
        .ant-input{
          background-color: transparent!important;
          box-shadow: none;
        }
      }
    }
    .sub-input{
      .ant-form-item-control-input{
        border-bottom: 1px solid #9A9A9A;
      }
    }
  }
  .three-link{
    display: flex;
    justify-content: flex-end;
    position: relative;
    margin-top: 20px;
    .anticon {
      font-size: 36px;
      color: #272727;
      cursor: pointer;
      margin-right: 20px;
    }
    .wx-link{
      position: relative;
      .wx-img{
        display: none;
        position: absolute;
        left: -140px;
        bottom: 0;
        width: 130px;
        height: 130px;
        object-fit: contain;
      }
      &:hover{
        .wx-img{
          display: block;
        }
      }
    }
  }
  .gongan{
    height: 14px;
    margin-right: 4px;
  }

}