// pc端样式
@media only screen and (min-width: 1081px) {
  .header {
    width: 100%;
    background-color: transparent;
  }
}

// 移动端样式
@media only screen and (max-width: 1080px) {
  .header {
    width: 100%;
    height: 75px;
    background-color: #fff;
  }
}