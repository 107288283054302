.mobile-home{
  .media_content{
    position: relative;
    object-fit: inherit;
    width: 100%;
    height: 100%;
    .media-img{
      width: 100%;
      height: 100%;
      object-fit: inherit;
    }
    .video-docker{
      display: block;
      object-position: unset;
      z-index: 0;
      video{
        object-fit: cover;
        object-position: center;
      }
    }
    
    .media-video{
      width: 100%;
      height: 100%;
      object-fit: inherit;
      display: block;
    }
  }
  .position-model{
    position: absolute;
    display: flex;
    flex-direction: column;
    color: #000;
    z-index: 21;
    top: 0!important;
    left: 0!important;
    width: 100%!important;
    height: 100%!important;
    overflow: hidden;
    font-family: "Centra Book",Arial;
  }
}