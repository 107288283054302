.pc-warmaf{
  background-color: #000;
  font-family: "Centra Book",Arial;
  color: #fff;
  font-size: 17px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .top1{
    padding: 90px 0;
  }
  .one-screen{
    height: calc(100vh - 140px);
    width: 100%;
    position: relative;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding: 23vh 0 18vh;
    .more-cta{
      .banner-arrow{
        border: solid #ffffff;
        border-width: 0 2px 2px 0;
        display: inline-block;
        padding: 6px;
        transform: rotate(45deg);
        margin-top: 16px;
      }
    }
  }
  .title{
    font-size: 72px;
    letter-spacing: 4px;
    margin-bottom: 15px;
    line-height: 1.2;
    text-align: center;
  }
  p{
    color: #fff;
    letter-spacing: 2px;
    margin-bottom: 0;
    font-size: 24px;
    margin-block-end: 1em;
    display: block;
    text-align: center;
  }
  a{
    color: #fff;
    &:hover{
      text-decoration: underline;
    }
  }
  .quote{
    font-size: 32px;
    margin-bottom: 32px;
  }
  .quotee{
    font-size: 23px;
    margin-bottom: 5px;
  }
  .fineprint{
    font-size: 15px;
  }
  .animate{
    position: relative;
    top: 0;
    left: 0;
  }

  .top2{
    padding: 90px 0;
    .the-shell{
      font-size: 40px;
      margin-top: 60px;
    }
    .the-keep{
      font-size: 29px;
      margin-bottom: 90px;
    }
    .left{
      width: 705px;
      position: relative;
      .left-img{
        width: 250px;
        padding-bottom: 60px;
      }
      ul{
        width: 435px;
        position: absolute;
        left: 270px;
        top:0;
        line-height: 1.43;
        font-size: 17px;
        li{
          position: absolute;
          left: 0;
          &:nth-child(1){
            top: 32px;
          }
          &:nth-child(2){
            top: 178px;
          }
          &:nth-child(3){
            top: 271px;
          }
          &:nth-child(4){
            top: 361px;
          }
          &:nth-child(5){
            top: 492px;
          }
        }
      }
      .tip{
        position: absolute;
        top: 580px;
        left: 5px;
        font-size: 17px;
      }
    }
    .right {
      width:705px;
      .tips{
        color: #fff;
        font-size: 17px;
        line-height: 1.43;
      }
      img{
        width: 100%;
      }
      .equal{
        text-align: center;
        font-size: 17px;
        padding: 20px 0;
        position: relative;
        background-color: #000;
        z-index: 3;
        color: #fff;
        &::before{
          position: absolute;
          content: "";
          width: 150px;
          height: 100%;
          background: #000000;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          z-index: -2;
        }
        &::after{
          position: absolute;
          content: "";
          width: 100%;
          height: 2px;
          background: #ffffff;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
          z-index: -4;
        }
      }
      .fp-class{
        display: flex;
        div{
          flex: 1;
          text-align: center;
          font-size: 17px;
        }
      }
      .fp-desc{
        text-align: center;
        width: 100%;
        .desc-one{
          width: 50%;
          margin: 5px 0 15px;
        }
        .desc-two{
          width: 63%;
          margin: 5px 0 15px;
          position: relative;
          text-align: right;
          &::after{
            position: absolute;
            content: "";
            width: 2px;
            height: 40px;
            bottom: 28px;
            right: 2px;
            background: #ad9852;
          }
        }
        .desc-three{
          position: relative;
          width: 88%;
          text-align: right;
          margin: 5px 0 15px;
          &::after{
            position: absolute;
            content: "";
            width: 2px;
            height: 86px;
            bottom: 28px;
            right: 2px;
            background: #ad9852;
          }
        }
      }
    }

  }
  .top3{
    .title{
      font-size: 40px;
      color: #AD9752;
    }
    .metal{
      display: flex;
      margin-top: 90px;
      justify-content: center;
      .left{
        width: 705px;
        line-height: 41px;
        font-size: 29px;
        dl{
          dt{
            margin-bottom: 10px;
          }
          dd{
            margin-bottom: 10px;
            &::before{
              content: '';
              display: inline-block;
              width: 10px;
              height: 10px;
              border-radius: 50%;
              background-color: #fff;
              vertical-align: middle;
              margin: 0 15px;
            }
          }
        }
      }
      .right{
        width: 705px;
        ul.top-imgs{

          li{
            margin-right: 25px;
            img{
              width: 120px;
              height: 120px;
            }
            span{
              font-size: 17px;
              line-height: 2.4;
              color: #fff;
            }
          }
        }
        ul.btm-txt{
          margin-top: 60px;
          font-size: 17px;
          line-height: 2.4;
          color: #fff;
        }
      }
    }
  }
  .top4{
    width: 1440px;
    padding: 90px 0;
    ul.sage{
      width: 100%;
      margin: 0 auto;
      display: flex;
      justify-content: center;
      li{
        width: 640px;
        align-items: center;
        padding: 20px 40px;
        img{
          width: 120px;
          height: 120px;
        }
        span{
          margin-left: 50px;
          width: 420px;
        }
      }
    }
  }
  .top5{
    padding: 90px;
    .title{
      font-size: 29px;
      line-height: 72px;
      margin-bottom: 10px;
    }
    .pay{
      display: flex;
      padding: 60px;
      justify-content: center;
      img{
        height: 80px;
        width: auto;
        padding: 0 30px;
      }
    }
    .text-cont{
      font-size: 17px;
      line-height: 42px;
      text-align: center;
    }
  }
  .btm{
    padding: 90px 0;
    .title{
      font-size: 40px;
      line-height: 76px;
      margin-top: 5px;
      color: #AD9752;
    }
    .link{
      font-size: 29px;
      line-height: 44px;
      justify-content: center;
      display: flex;
      a{
        text-decoration: none;
        margin-right: 40px;
        &:hover{
          text-decoration: underline;
        }
      }
    }
  }
  // 转为大写
  .upper{
    text-transform: uppercase;
  }
  // color
  .colorAD9{
    color: #AD9752; 
  }
  .pd90{
    padding: 90px 0;
  }
}