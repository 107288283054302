.mob-warmaf{
  background-color: #000;
  font-family: "Centra Book",Arial;
  color: #fff;
  font-size: 17px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 15px;
  overflow-x: hidden;
  .top1{
    padding: 0 0 90px;
    .one-screen{
      height: calc(100vh - 1.3751rem);
      width: 100%;
      position: relative;
      display: flex;
      justify-content: center;
      flex-direction: column;
      text-align: center;
      .title{
        font-size: 32px;
      }
      .more-cta{
        margin-top: 50px;
        .banner-arrow{
          border: solid #ffffff;
          border-width: 0 2px 2px 0;
          display: inline-block;
          padding: 6px;
          transform: rotate(45deg);
        }
      }
      .sub-title{
        font-size: 17px;
        letter-spacing: 2px;
        text-transform: uppercase;
        line-height: 24px;
      }
    }
    .top1-btm{
      text-align: center;
      padding: 0 10px;
      .quote{
        font-size: 23px;
      }
      .quotee{
        margin: 30px 0 10px;
        font-size: 16px;
      }
      .fineprint{
        font-size: 12px;
      }
    }
  }
  
  // .title{
  //   font-size: 27px;
  //   letter-spacing: 4px;
  //   margin-bottom: 15px;
  //   line-height: 1.2;
  //   text-align: center;
  // }
  // p{
  //   color: #fff;
  //   letter-spacing: 2px;
  //   margin-bottom: 0;
  //   font-size: 17px;
  //   margin-block-end: 1em;
  //   display: block;
  //   text-align: center;
  // }
  a{
    color: #fff;
    &:hover{
      text-decoration: underline;
    }
  }

  .top2{
    padding: 40px 0;
    .title{
      font-size: 27px;
    }
    .the-shell{
      font-size: 22px;
      margin: 30px 0 10px;
    }
    .the-keep{
      font-size: 18px;
      text-align: center;
    }
    .left{
      width: 100%;
      position: relative;
      padding: 60px 10px 140px;
      .left-img{
        width: 140px;
        padding-bottom: 60px;
      }
      ul{
        line-height: 1.43;
        font-size: 12px;
        position: relative;
        flex: 1;
        li{
          position: absolute;
          left: 20px;
          &:nth-child(1){
            top: 0;
          }
          &:nth-child(2){
            top: 99px;
          }
          &:nth-child(3){
            top: 129px;
          }
          &:nth-child(4){
            top: 201px;
          }
          &:nth-child(5){
            top: 269px;
          }
        }
      }
      .tip{
        position: absolute;
        bottom: 80px;
        left: 5px;
        font-size: 17px;
      }
    }
    .right {
      width:100%;
      .tips{
        color: #fff;
        font-size: 16px;
        line-height: 1.43;
      }
      img{
        width: 96%;
        margin: 10px auto;
      }
      .equal{
        text-align: center;
        font-size: 16px;
        padding: 20px 0;
        position: relative;
        background-color: #000;
        z-index: 3;
        color: #fff;
        &::before{
          position: absolute;
          content: "";
          width: 150px;
          height: 100%;
          background: #000000;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          z-index: -2;
        }
        &::after{
          position: absolute;
          content: "";
          width: 100%;
          height: 2px;
          background: #ffffff;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
          z-index: -4;
        }
      }
      .fp-class{
        display: flex;
        div{
          flex: 1;
          text-align: center;
          font-size: 16px;
        }
      }
      .fp-desc{
        text-align: center;
        width: 100%;
        font-size: 14px;
        .desc-one{
          width: 50%;
          margin: 5px 0 15px;
        }
        .desc-two{
          width: 63%;
          margin: 5px 0 15px;
          position: relative;
          text-align: right;
          &::after{
            position: absolute;
            content: "";
            width: 2px;
            height: 40px;
            bottom: 28px;
            right: 2px;
            background: #ad9852;
          }
        }
        .desc-three{
          position: relative;
          width: 88%;
          text-align: right;
          margin: 5px 0 15px;
          &::after{
            position: absolute;
            content: "";
            width: 2px;
            height: 86px;
            bottom: 28px;
            right: 2px;
            background: #ad9852;
          }
        }
      }
    }

  }
  .top3{
    .title{
      font-size: 22px;
      color: #AD9752;
      text-align: center;
    }
    .metal{
      display: flex;
      margin-top: 60px;
      flex-direction: column;
      .left{
        width: 100%;
        line-height: 1.6;
        font-size: 17px;
        dl{
          dt{
            margin-bottom: 10px;
          }
          dd{
            margin-bottom: 10px;
            &::before{
              content: '';
              display: inline-block;
              width: 10px;
              height: 10px;
              border-radius: 50%;
              background-color: #fff;
              vertical-align: middle;
              margin: 0 20px;
            }
          }
        }
      }
      .right{
        width: 100%;
        margin-top: 60px;
        ul.top-imgs{
          display: flex;
          justify-content: center;
          li{
            margin-right: 25px;
            &:last-child{
              margin-right: 0;
            }
            img{
              width: 70px;
              height: 70px;
            }
            span{
              font-size: 17px;
              line-height: 2.4;
              color: #fff;
            }
          }
        }
        ul.btm-txt{
          margin-top: 60px;
          font-size: 17px;
          line-height: 2.4;
          color: #fff;
          padding: 0 20px;
        }
      }
    }
  }
  .top4{
    width: 100%;
    padding: 0 0 90px;
    ul.sage{
      margin: 0 auto;
      display: flex;
      justify-content: center;
      li{
        width: 100%;
        align-items: center;
        padding: 20px 10px;
        img{
          width: 90px;
          height: 90px;
          margin-right: 25px;
        }
        span{
          flex: 1;
          font-size: 15px;
        }
      }
    }
  }
  .top5{
    padding: 0 0 90px;
    .title{
      font-size: 19px;
      line-height: 32px;
      margin-bottom: 10px;
      text-align: center;
      padding: 0 10px;
    }
    .pay{
      display: flex;
      padding: 60px;
      justify-content: center;
      img{
        height: 80px;
        width: auto;
        padding: 0 30px;
      }
    }
    .text-cont{
      font-size: 16px;
      line-height: 2;
      text-align: center;
      padding: 0 10px;
    }
  }
  .btm{
    padding: 0 0 90px;
    text-align: center;
    .title{
      font-size: 22px;
      line-height: 1.6;
      color: #AD9752;
    }
    .link{
      font-size: 18px;
      line-height: 1.4;
      a{
        margin-top: 30px;
        text-decoration: none;
        &:hover{
          text-decoration: underline;
        }
      }
    }
  }
  // 转为大写
  .upper{
    text-transform: uppercase;
  }
  // color
  .colorAD9{
    color: #AD9752; 
  }
  .pd90{
    padding: 90px 0;
  }
}