.pc-modal{
  .sub-modal{
    width: 100%;
    height: 485px;
    .sub-img{
      width: 340px;
      object-fit: cover;
    }
    .sub-cont{
      flex: 1;
      padding: 0 36px 0 25px;
      .sub-title{
        font-size: 30px;
        line-height: 48px;
        letter-spacing: 12px;
        margin-top: 28px;
        margin-bottom: 40px;
      }
    }
    .sub-input{
      .ant-form-item-control-input{
        border-bottom: 1px solid #9A9A9A;
      }
    }
    .ant-input{
      background-color: transparent!important;
      box-shadow: none;
    }
  }
}