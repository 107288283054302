.pc-header {
  position: fixed;
  z-index: 1002;
  width: 100%;
  height: 156px;
  padding: 0 50px;
  box-sizing: border-box;
  font-family: "Centra Book", Arial;
  // border-bottom: 1px solid #dcddde;
  transition: height .5s;

  &:hover {
    color: #272727 !important;
    background-color: #dcddde !important;
  }

  .h-top {
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding-top: 6px;
    transition: height .4s, top .4s;
    position: relative;
    top: 0;
    left: 0;

    p {
      margin: 0;
    }
  }

  .hide-top {
    height: 0 !important;
    top: -60px;
    z-index: -1;
  }

  .h-bottom {
    width: 100%;
    height: 78px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;

    .logo {
      width: 240px;
    }

    .h-mid {
      display: flex;
      height: 100%;
      align-items: center;
      position: absolute;
      margin: 0;
      left: 50%;
      top: 0;
      transform: translateX(-50%);

      li {
        margin-left: 40px;
        cursor: pointer;
        font-size: 16px;
        font-weight: 700;

        &.active {
          border-bottom: 1px solid #000;
        }
      }
    }

    .h-right {
      align-items: center;

      img {
        margin-left: 20px;
        cursor: pointer;
        width: 22px;
        height: 22px;
      }

      .user-email {
        height: 20px;
        width: 26px;
      }
    }
  }

  .sub-nav {
    position: absolute;
    top: 130px;
    left: 0;
    width: 100%;
    color: #272727;
    display: flex;
    flex-direction: column;

    .nav-box {
      transition: all .4s;
      background-color: #fff;
      z-index: 100;
      width: 100%;
      display: flex;
      justify-content: center;
      box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.16);
      position: relative;

      dl {
        padding: 50px 10px 0;
        margin: 0;
        min-width: 180px;
        text-align: center;
        position: relative;

        dt {
          color: #000;
          font-size: 15px;
          font-weight: 700;
          line-height: 40px;
        }

        dd {
          cursor: pointer;
          line-height: 40px;
          font-size: 14px;

          &:last-child {
            padding-bottom: 50px;

            a {
              color: #000;
              font-weight: 700;
              font-size: 15px;
            }
          }

          a {
            color: #717171;

            &:hover {
              text-decoration: underline;
            }
          }
        }
      }

      .sub-img-box {
        background-color: #fff;
        margin-right: 1px;
        flex: 1;
        height: auto;
        position: relative;

        &:last-child {
          margin-right: 0;
        }

        img {
          width: 100%;
          object-fit: contain;
        }
      }
    }

  }

  .show-sub-nav {
    .shadow {
      position: absolute;
      left: 0;
      top: 0;
      background: rgba(0, 0, 0, 0.3);
      width: 100%;
      height: 100%;
      z-index: 2;
    }
  }

  .hide-sub-nav {
    height: 0;
  }

  .search-content {
    width: 1290px;
    height: auto;
    position: fixed;
    top: 1.89836rem;
    left: 305px;
    background-color: #dcddde;
    box-shadow: 0 0 10px #ccc;
    color: #272727;

    .search-control {
      width: 100%;
      height: 80px;
      padding: 0 40px;

      .search-input {
        height: 100%;
        flex: 1;
        border: none;
        background-color: transparent;
        outline: medium;
        font-size: 20px;
        padding: 0 24px;
      }

      .search-icon {
        width: 20px;
        height: 20px;
      }

      .close-icon {
        width: 14px;
        height: 14px;
        cursor: pointer;
      }
    }

    .search-result {
      width: 100%;

      .search-line {
        height: 1px;
        width: 96%;
        margin: 0 auto;
        background-color: #000;
        position: relative;
        // 竖线
        // &::after{
        //   content: '';
        //   display: block;
        //   width: 1px;
        //   height: 380px;
        //   background-color: #000;
        //   position: absolute;
        //   top: 20px;
        //   left: 50%;
        // }
      }

      .res-content {
        height: 420px;
        overflow-y: auto;
        padding: 20px;

        .res-left {
          padding-left: 60px;
          min-height: 100%;

          ul.res-link {
            text-indent: 2em;
            font-size: 18px;
            line-height: 46px;

            li {
              a {
                &:hover {
                  text-decoration: underline;
                }
              }
            }
          }
        }

        .res-right {
          padding-left: 20px;

          ul.res-plp {
            padding: 0 0 30px 30px;
            width: 100%;
            display: flex;
            flex-wrap: wrap;

            li {
              margin-right: 20px;
              margin-top: 20px;
              background-color: #fff;

              a img {
                width: 160px;
                height: 160px;
                object-fit: contain;
              }
            }
          }
        }

        .res-title {
          font-size: 20px;
          font-weight: bold;
          line-height: 44px;
        }
      }
    }
  }
}