.pc-home{
  transform: translate3d(0);
  .media_content{
    position: relative;
    width: 100%;
    height: 100%;
    object-fit: inherit;
    .media{
      width: 100%;
      height: 100%;
      object-fit: inherit;
    }
  }
  .position-model{
    position: absolute;
    z-index: 21;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    font-family: "Centra Book",Arial;
  }
}