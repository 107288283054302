.w100{
  width: 100%;
}
// margin
.mt2{
  margin-top: 2px;
}
.mr2{
  margin-right: 2px;
}
.ml2{
  margin-left: 2px;
}
.mb2{
  margin-bottom: 2px;
}
.mt5{
  margin-top: 5px;
}
.mr5{
  margin-right: 5px;
}
.ml5{
  margin-left: 5px;
}
.mb5{
  margin-bottom: 5px;
}

.mt10{
  margin-top: 10px;
}
.mr10{
  margin-right: 10px;
}
.ml10{
  margin-left: 10px;
}
.mb10{
  margin-bottom: 10px;
}

.mb15{
  margin-bottom: 15px;
}
.mt15{
  margin-top: 15px;
}
.mr15{
  margin-right: 15px;
}
.ml15{
  margin-left: 15px;
}

.mt20{
  margin-top: 20px;
}
.mr20{
  margin-right: 20px;
}
.ml20{
  margin-left: 20px;
}
.mb20{
  margin-bottom: 20px;
}
.mt30{
  margin-top: 30px;
}
.mr30{
  margin-right: 30px;
}
.ml30{
  margin-left: 30px;
}
.mb30{
  margin-bottom: 30px;
}
.mt40{
  margin-top: 40px;
}
.mr40{
  margin-right: 40px;
}
.ml40{
  margin-left: 40px;
}
.mb40{
  margin-bottom: 40px;
}
.mt60{
  margin-top: 60px;
}
.mr60{
  margin-right: 60px;
}
.ml60{
  margin-left: 60px;
}
.mb60{
  margin-bottom: 60px;
}

// padding
.pdt10{
  padding-top: 10px;
}
.pdl10{
  padding-left: 10px;
}
.pdr10{
  padding-right: 10px;
}
.pdb10{
  padding-bottom: 10px;
}
.pdt20{
  padding-top: 20px;
}
.pdl20{
  padding-left: 20px;
}
.pdr20{
  padding-right: 20px;
}
.pdb20{
  padding-bottom: 20px;
}

// 行高
.lh{
  line-height: 17px;
}

.lh32{
  line-height: 32px;
}

// 字号
.f12{
  font-size: 12px;
}
.f13{
  font-size: 13px;
}
.f14{
  font-size: 14px;
}
.f16{
  font-size: 16px;
}
.f18{
  font-size: 18px;
}
.f20{
  font-size: 20px;
}
.f22{
  font-size: 22px;
}
.f24{
  font-size: 24px;
}
.f26{
  font-size: 26px;
}
.f28{
  font-size: 28px;
}

// 颜色
.color000{
  color: #000;
}
.color333{
  color: #333;
}
.color707{
  color: #707070;
}
.color1B1, .color1b1{
  color: #1B1B1B;
}

// 字体加粗
.fbold{
  font-weight: bold;
}

// 下划线
.txt-under{
  text-decoration: underline;
}
// 可点击
.txt-pointer{
  cursor: pointer;
}

// 中划线
.txt-thr{
  text-decoration: line-through;
}

// 字居中
.txt-center{
  text-align: center;
  &:hover{
    text-align: center;
  }
}

// 底部边框-form-input
.border-btm{
  border-bottom: 1px solid #9A9A9A;
}
// 2pxborder
.border-2px{
  border-width: 2px;
}

// 超出一行显示...
.ellipsis{
  overflow: hidden;
  text-overflow:ellipsis;
  white-space: nowrap;
  -webkit-line-clamp:1;
}

/* 超出两行显示...需要写宽度 */
.ellipsis2{
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

/* 超出三行显示...需要写宽度 */
.ellipsis3{
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
}

// pc专用
  // 按钮
.pc-btn-lg{
  min-width: 140px;
}
// 表单提示
.pc-tips{
  font-size: 12px;
  line-height: 2;
  height: 24px;
  color: #9D2828;
}

// H5专用
  // 按钮
.pc-btn330{
  width: 330px;
  height: 40px;
  background-color: #000;
  color: #fff;
  line-height: 40px;
  text-align: center;
  cursor: pointer;
}
.pc-tips{
  font-size: 12px;
  line-height: 2;
  height: 24px;
  color: #9D2828;
}
