.pc-home{
  // 1张图片
  .img-component-big-img{
    position: relative;
    object-fit: cover;
    overflow: hidden;
    width: 100%;
  }
  // 轮播左右按钮
  .swiper-menu{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 13px;
    height: 51px;
    cursor: pointer;
  }

  //  1张图+文字---两栏位
  .img-one-text{
    overflow: hidden;
    width: 100%;
    display: flex;
    align-items: center;
    font-size: 15px;
    line-height: 29px;
    p{
      margin: 0!important;
    }
    .left-img{
      width: 50vw;
    }
    .html-content{
      padding: 53px;
      .title{
        font-size: 30px;
        line-height: 3;
        text-align: center;
      }
    }
  }

  // 两张图片、每个图片都有浮动层
  .img-two{
    width: 100%;
    overflow: hidden;
    flex-wrap: wrap;
    position: relative;
    .img-item{
      object-fit: contain;
      width: 50%;
      position: relative;
      .href-link{
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: transparent;
        z-index: 20;
        display: block;
      }
    }
    .position-center{
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 10;
      .position-model{
        width: 100%;
        height: 100%;
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        z-index: 3;
      }

    }
  }
  // 3张图片或视频
  .img-three{
    overflow: hidden;
    flex-wrap: wrap;
    position: relative;
    .img-item{
      width: 33.3%;
      object-fit: contain;
    }
    .position{
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 10;
      .position-model{
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
      }
    }
  }


  // 轮播
  .full-screen-carousel {
    position: relative;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    .ant-carousel {
      width: 100vw;
      height: 100vh;
      .carousel-item {
        width: 100vw;
        height: 100vh;
        position: relative;
        object-fit: cover;
        overflow: hidden;
      }
    }
    p {
      margin: 0;
    }
  }

  // 4张图片
  .img-four{
    width: 100%;
    display: flex;
    overflow: hidden;
    .four-item{
      display: flex;
      flex-direction: column;
      position: relative;
      flex: 1;
      margin-right: 3px;
      &:last-child{
        margin-right: 0;
      }
      .four-title{
        font-size: 14px;
        line-height: 2;
        margin-top: 20px;
        text-align: center;
      }
      .four-price{
        font-size: 14px;
        line-height: 2;
        color: #797979;
        text-align: center;
        margin-bottom: 20px;
      }
    }
    .last-item{
      font-family: "Centra Book",Arial;
      height: auto;
    }
  }


  // 5图轮播
  .img-five-carousel{
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #FFF;
    padding: 20px 10px;
    .menu-left{
      left: 23px;
    }
    .menu-right{
      right: 23px;
    }
    .carousel {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      .ant-carousel {
        width: 100%;
      }
      .slick-slide{
        padding: 0 5px;
      }
      .good-item {
        width: 365px;
        .good-link {
          background-color: #fff;
          display: block;
          width: 100%;
          width: 365px;
          .good-img {
            width: 100%;
            object-fit: contain;
          }
        }
        
        .good-info {
          width: 100%;
          .good-name {
            margin-top: 27px;
            width: 100%;
            font-size: 14px;
            text-align: center;
            line-height: 2;
          }
          .good-price {
            width: 100%;
            font-size: 14px;
            line-height: 2;
            color: #797979;
            text-align: center;
            margin-bottom: 20px;
          }
        }
      }
        
      .anticon {
        font-size: 30px;
        color: #272727;
        cursor: pointer;
        position: absolute;
        top: 245px;
      }
      .anticon-left-circle {
        left: 25px;
      }
      .anticon-right-circle {
        right: 25px;
      }
    }
  }

// 纯文字模块
  .empty-block {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: "Centra Book",Arial;
    font-size: 14px;
    line-height: 1.5;
    background-color: #fff;
    .empty-cont{
      width: 80%;
      padding: 40px 100px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

}

