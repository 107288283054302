// 重置html
@import './style/reset.scss';
// flex封装
@import './style/flex.scss';
// 常用字号、颜色、margin、padding、按钮等
@import './style/global.scss';
// 浮动层、纯文字模块、的样式
@import './style/float.scss';
#app{
  position: relative;
}
