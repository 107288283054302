.mob-modal{
    .sub-modal-mob{
    width: 402px;
    .sub-cont{
      padding: 0 15px;
      .sub-title{
        font-size: 30px;
        line-height: 48px;
        letter-spacing: 12px;
        margin-top: 70px;
        margin-bottom: 43px;
        text-align: center;
      }
      .sub-tips{
        font-size: 19px;
        line-height: 28px;
        color: #151515;
        margin-bottom: 27px;
      }
    }
    .sub-input{
      .ant-form-item-control-input{
        border-bottom: 1px solid #9A9A9A;
      }
    }
    .ant-input{
      background-color: transparent!important;
      box-shadow: none;
    }
  }
}