.mobile-home{
  // 1张图片

  .one-img {
    width: 100%;
    position: relative;
  }


  //  1张图+文字---两栏位
  .img-one-plus-text{
    font-family: "Centra Book",Arial;
    background-color: #EDEDED;
    .img, .video{
      width: 100%;
    }
    .title{
      width: 100%;
      height: 100%;
      font-size: 24px;
      line-height: 48px;
      text-align: center;
      padding: 0 40px;
      box-sizing: border-box;
    }
  }

  // 两张图片/视频
  .img-two-box{
    position: relative;
    .img-two{
      width: 100%;
      position: relative;
      .img-item{
        width: 100%;
        position: relative;
      }
    }
    .float-center{
      position: absolute;
      width: 100%!important;
      height: 100%;
      top: 0!important;
      left: 0!important;
      .postion-center, .postion-right, .postion-left, .postion-top, .postion-btm{
        position: relative!important;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        padding: 20px 0;
        box-sizing: border-box;
      }
    }
  }

  // 3、4、5张图片
  .imgfour, .imgFive, .imgthree{
    // 轮播图
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    .ant-carousel {
      width: 100%;
      .carousel-item {
        width: 100%;
        .good-img {
          width: 100%;
          display: block;
          object-fit: contain;
        }
        .good-info {
          width: 100%;
          p {
            margin: 0;
          }
          .good-name {
            margin-top: 15px;
            font-size: 14px;
            line-height: 2;
            color: #272727;
            text-align: center;
            padding: 0 20px;
            box-sizing: border-box;
          }
          .good-price {
            font-size: 14px;
            line-height: 2;
            color: #797979;
            text-align: center;
            margin-bottom: 20px;
          }
        }
      }
    }
    .anticon {
      font-size: 36px;
      position: absolute;
      top: 50%;
      margin-top: -90px;
    }
    .anticon-left {
      color: rgba(250, 250, 250, .1);
      left: 16px;
    }
    .anticon-right {
      color: rgba(250, 250, 250, .1);
      right: 16px;
    }
    .footer-menu{
      position: relative;
      width: 100%;
      .react-html{
        .postion-center, .postion-right, .postion-left, .postion-top, .postion-btm{
          position: relative!important;
          display: flex;
          justify-content: center;
          flex-direction: column;
          align-items: center;
          padding: 20px 0;
        }
      }
    }
  }
  .imgthree{
    .default-html{
      .title-h1, .title-h2, .title-h3, .title-h4, .title-h5, .title-h6, .text-content {
        color: #000!important;
      }
    }
  }

  // 轮播图
  .img-carousel {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    .ant-carousel {
      width: 100%;
      .carousel-item {
        width: 100%;
        .good-img {
          width: 100%;
          height: 588px;
          display: block;
          object-fit: cover;
        }
        .good-info {
          width: 100%;
          p {
            margin: 0;
          }
          .good-name {
            font-size: 23px;
            line-height: 48px;
            color: #272727;
            text-align: center;
            padding: 0 20px;
            box-sizing: border-box;
          }
          .good-price {
            font-size: 18px;
            color: #797979;
            text-align: center;
          }
        }
      }
    }
    .anticon {
      font-size: 36px;
      color: #272727;
      position: absolute;
      top: 50%;
      margin-top: -90px;
    }
    .anticon-left {
      left: 16px;
    }
    .anticon-right {
      right: 16px;
    }
  }

  // 4张以上轮播
  .group-box{
    display: flex;
    flex-direction: column;
    align-items: center;
    .title{
      font-size: 16px;
      letter-spacing: 1.92px;
      line-height: 32px;
      text-align: center;
    }
    .group-btns{
      width: 160px;
      height: 32px;
      border-radius: 16px;
      overflow: hidden;
      display: flex;
      background-color: #fff;
      margin: 10px 0;
      .group-btn{
        flex: 1;
        text-align: center;
        line-height: 32px;
      }
      .group-btn-active{
        background-color: #000;
        color: #fff;
      }
    }
    // 轮播图
    .group-carousel {
      width: 100%;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      margin-top: 15px;
      .ant-carousel {
        width: 100%;
        .carousel-item {
          width: 100%;
          .good-img {
            width: 100%;
            height: 489px;
            display: block;
            object-fit: contain;
          }
          .good-info {
            width: 100%;
            margin-top: 27px;
            p {
              margin: 0;
            }
            .good-name {
              font-size: 23px;
              line-height: 48px;
              color: #272727;
              text-align: center;
              padding: 0 20px;
              box-sizing: border-box;
            }
            .good-price {
              font-size: 18px;
              color: #797979;
              text-align: center;
            }
          }
        }
      }
      .anticon {
        font-size: 36px;
        color: #272727;
        position: absolute;
        top: 50%;
        margin-top: -90px;
      }
      .anticon-left {
        left: 16px;
      }
      .anticon-right {
        right: 16px;
      }
    }
    
    .footer-btn{
      margin: 40px 0 77px;
      height: 57px;
      line-height: 57px;
      padding: 0 28px;
      color: #000;
      border:1px solid #000;
    }
  }

  .empty-block {
    width: 100vw;
    overflow-x: hidden;
    padding: 40px 2em;
    position: relative;
    box-sizing: border-box;
  }
}