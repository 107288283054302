/*全局flex布局*/
.fx{
  display: flex;
}
/*垂直显示*/
.fx-col{
  display: flex;
  flex-direction: column;
}

/* 主轴对齐方式1 横向居中*/
.fx-sc{
  display: flex;
  justify-content: center;
}
/* 主轴对齐方式2 两端对齐*/
.fx-sb{
  display: flex;
  justify-content: space-between;
}
/* 主轴对齐方式3 间隔对齐 每个项目两侧的间隔相等*/
.fx-sa{
  display: flex;
  justify-content: space-around;
}
/* 主轴对齐方式3 左对齐*/
.fx-sl{
  display: flex;
  justify-content: flex-start;
}
/* 主轴对齐方式4 右对齐*/
.fx-sr{
  display: flex;
  justify-content: flex-end;
}

/* 侧轴对齐 默认*/
.align-a{
  display: flex;
  align-items: flex-start;
}
/* 侧轴对齐 末尾对齐*/
.align-b{
  display: flex;
  align-items: flex-end;
}
/* 侧轴对齐 居中对齐*/
.align-c{
  display: flex;
  align-items: center;
}

/* 水平方向 常用1 */
.fx-sc-c{
  display: flex;
  justify-content: center;
  align-items: center;
}
/* 水平方向 常用2 */
.fx-sb-c{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
/* 水平方向 常用3 */
.fx-sa-c{
  display: flex;
  justify-content: space-around;
  align-items: center;
}

/* 垂直方向 常用1 */
.fx-col-sa-c{
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

/* 垂直方向 常用2 */
.fx-col-sb-c{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

/* 垂直方向 常用3 */
.fx-col-sc-c{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* 其他1 换行*/
.fx-wrap{
  flex-wrap: wrap;
}

/*其他2 flex1 伸缩盒子布局兼容*/
.flex1{
  flex-grow: 1;
  flex: 1;
}
.flex2{
  flex: 2;
}
.flex3{
  flex: 3;
}

/*flex布局---end*/
