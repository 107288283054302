.pc-error{
  min-height: calc(100vh - 130px - 418px);
  font-size: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align:center;
  color:#000;
  padding:40px;
  flex-direction: column;
  .title{
    padding:20px 0 40px;
    font-size:32px;
    line-height:80px;
    .oops{
      margin-top: -40px;
    }
  }
  .sub-title{
    font-size:17px;
    line-height:34px;
  }
}
.mob-error{
  min-height: calc(100vh - 75px - 450px);
  font-size: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align:center;
  color:#000;
  padding:40px;
  .title{
    padding:20px 0 40px;
    font-size:32px;
    line-height:80px;
    .oops{
      margin-top: -40px;
    }
  }
  .sub-title{
    font-size:17px;
    line-height:34px;
  }
}