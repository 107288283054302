@import './font.scss';

// 重置标签样式
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
form,
fieldset,
legend,
input,
textarea,
p,
blockquote,
th,
td {
  margin: 0;
  padding: 0;
}

html {
  width: 100%;
  min-height: 100vh;
}
body {
  font-family: 'Open Sans','Helvetica Neue',Helvetica,Arial,sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 12px;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100vw;
  min-height: 100vh;
  background-color: #fff;
  position: relative;
}

ul, ol, li {
  list-style: none;
}

a {
  text-decoration: none;
  color: #000;
  &.txt-under{
    text-decoration: underline;
  }
}

img {
  display: block;
}


@media screen and (min-width: 1081px) {
  html {
    // ui 宽度 1920px
    // 1vw = 19.2px
    // rootValue = 4.28 * 19.2
    // font-size = 4.28vw = 19.2 * 4.28 px
    // write = font-size / rootValue = 1px
    // 在ui宽度下为 82.176px
    font-size: 4.28vw!important;
  }
}

@media screen and (max-width: 1080px) {
  html {
    // ui 宽度 428px
    // 1vw = 4.28px
    // rootValue = 4.28 * 19.2
    // font-size = 19.2vw = 19.2 * 4.28 px
    // write = font-size / rootValue = 1px
    // 在ui宽度下为 82.176px
    font-size: 19.2vw!important;
  }
}



// 滚动条样式
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: transparent;
}
::-webkit-scrollbar-track {
  border-radius: 5px;
}
::-webkit-scrollbar-thumb {
  background-color: rgba(35, 24, 21, 0.6);
  transition: all .2s;
  border-radius: 5px;
}
::-webkit-scrollbar-thumb:hover {
  background-color: rgba(35, 24, 21, 0.9);
}

