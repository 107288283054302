.mobile-footer {
  width: 100%;
  padding: 64px 0 10px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #EDEDED;
  font-family: "Centra Book",Arial;
  .ant-collapse {
    width: 100%;
    .ant-collapse-item{
      display: flex;
      flex-direction: column;
      align-items: center;
      .ant-collapse-header{
        font-size: 18px;
      }
      .ant-collapse-content-box{
        padding-top: 0;
        padding-bottom: 0;
        a{
          line-height: 34px;
        }
      }
    }
    .ant-collapse-header {
      font-size: 20px;
      color: #272727;
    }
    .ant-collapse-content-box {
      p{
        text-align: center;
      }
      a {
        font-size: 15px;
        color: #9B9B9B;
      }
    }
  }
  .share-btn {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 64px;
    .anticon {
      font-size: 36px;
    }
    .anticon-wechat {
      margin-left: 16px;
    }
  }
  .mob-footer-form{
    width: 85vw;
  }
  .sub-input{
    .ant-form-item-control-input{
      border-bottom: 1px solid #9A9A9A;
      .ant-input{
        background-color: transparent!important;
        box-shadow: none;
      }
    }
  }
  .mob-btn-lg{
    background-color: #000;
    color: #fff;
  }
  .gongan{
    height: 14px;
  }
}